import { Pipe, PipeTransform } from '@angular/core';
import {DataHeader, DataIndex} from '../../data-table-display';

@Pipe({
    name: 'isHeaderNameSame',
    standalone: false
})
export class IsHeaderNameSame implements PipeTransform {
  transform(headers: DataHeader[][], colIndex: number, relatedCols: DataIndex[], headerArrayIndex: number): boolean {
    if (colIndex === 0) {
      return false;
    } else {
      let currentColName = headers[relatedCols[colIndex].index][headerArrayIndex].name;
      let previousColIndex = colIndex - 1;
      let previousColName = headers[relatedCols[previousColIndex].index][headerArrayIndex].name;
      return previousColName === currentColName;
    }
  }
}
