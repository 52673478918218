import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthyApiService } from '../../api-services/authy-api/authy-api.service';
import { LoginStateService } from '../login-state-service/login-state.service';
import { DeviceService } from '../device-service/device.service';
import { ApiService } from '../../api/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { RoutingService } from '../../core/services/routing.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-verify-phone',
    templateUrl: './verify-phone.component.html',
    styleUrls: ['./verify-phone.component.scss'],
    standalone: false
})
export class VerifyPhoneComponent implements OnInit {
  public verificationCode: number;
  private verifyPhoneSubscription: Subscription;
  private redirectUrl: string;
  private isRedirectClassicUrl: boolean;

  constructor(
    private authyService: AuthyApiService,
    private loginStateService: LoginStateService,
    private deviceService: DeviceService,
    private apiService: ApiService,
    private dashboardRoutingService: RoutingService,
    private snackBar: SnackBarService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.redirectUrl = params.get('redirect') ? params.get('redirect') : null;
      this.isRedirectClassicUrl = params.get('isClassic') ? params.get('isClassic') === 'true' : false;
    });
  }

  public verifyPhone() {
    if (!this.verifyPhoneSubscription) {
      this.verifyPhoneSubscription = this.authyService.verify({
        verification_code: this.verificationCode
      }).subscribe({
        next: (verifyPhoneResponse) => {
          this.deviceService.userDeviceId = verifyPhoneResponse.user_device_id;
          this.apiService.userAuthorization = verifyPhoneResponse.authorization;
          this.apiService.setAuthorizationRole(verifyPhoneResponse.authorization_role);
          if (verifyPhoneResponse.authorization_role === 'ROLE_ACCEPT_TERMS') {
            this.router.navigate(['/notifications/accept-terms'], {queryParamsHandling: 'preserve'});
          } else {
            if (this.redirectUrl) {
              this.dashboardRoutingService.redirectOnLogin(this.redirectUrl, this.isRedirectClassicUrl);
            } else {
              this.dashboardRoutingService.directToDashboard();
            }
          }
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBar.openErrorSnackBar(errorResponse.error.error);
        }
      });
      this.verifyPhoneSubscription?.add(() => this.verifyPhoneSubscription = null);
    }
  }

  public resendCode() {
    this.authyService.setup({
      phone_number: this.loginStateService.phoneNumber,
      country_code: this.loginStateService.countryCode.value,
      send_method: this.loginStateService.sendMethod
    }).subscribe({
      next: () => {
        this.snackBar.openSnackBar(('A verification code was sent to: ' + this.loginStateService.phoneNumber));
      },
      error: (errorResponse: HttpErrorResponse) => {
        this.snackBar.openErrorSnackBar(errorResponse.error.error);
      }
    });
  }
}
