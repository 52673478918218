import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[appValidateEqual][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EqualValidatorDirective),
            multi: true
        }
    ],
    standalone: false
})

export class EqualValidatorDirective implements Validator {
  public isMaster: boolean;

  constructor( @Attribute('appValidateEqual') public validateEqual: string, @Attribute('isMaster') isMaster: string) {

    this.isMaster = isMaster === 'true';
  }

  validate(control: AbstractControl): { [key: string]: any } {
    let currentElement = control;
    let comparedElement = control.root.get(this.validateEqual);

    if (!comparedElement) {
      return null;
    }

    if (currentElement.value !== comparedElement.value && !this.isMaster) {
      return {
        validateEqual: true
      };
    }

    if (currentElement.value !== comparedElement.value && this.isMaster) {
      comparedElement.setErrors({ validateEqual: true });
    }

    if (currentElement.value === comparedElement.value && this.isMaster) {
      delete comparedElement.errors['validateEqual'];
      if (!Object.keys(comparedElement.errors).length) {
        comparedElement.setErrors(null);
      }
    }

    return null;
  }
}
