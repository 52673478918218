import { Component, Input } from '@angular/core';

import { ProgressSpinnerService } from '../../../core/services/progress-spinner.service';

@Component({
    selector: 'app-progress-spinner',
    templateUrl: './progress-spinner.component.html',
    styleUrls: ['./progress-spinner.component.scss'],
    standalone: false
})
export class ProgressSpinnerComponent {

  constructor(private progressService: ProgressSpinnerService) { }

  public get isProgressVisible() {
    return this.progressService.isVisible;
  }

  public get mode() {
    return this.progressService.mode;
  }
}
