import {Component, AfterViewInit, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';

import { AuthyApiService } from '../../api-services/authy-api/authy-api.module';
import { ApiService } from '../../api/api.module';
import { LoginStateService } from '../login-state-service/login-state.service';
import { DeviceService } from '../device-service/device.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { RoutingService } from '../../core/services/routing.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-verify-device',
    templateUrl: './verify-device.component.html',
    styleUrls: ['./verify-device.component.scss'],
    standalone: false
})
export class VerifyDeviceComponent implements OnInit, AfterViewInit {
  public validationCode: number;
  private verifyCodeSubscription: Subscription;
  private redirectUrl: string;
  private isRedirectClassicUrl: boolean;

  constructor(
    private authyService: AuthyApiService,
    private loginStateService: LoginStateService,
    private cookieService: CookieService,
    private apiService: ApiService,
    private dashboardRoutingService: RoutingService,
    private deviceService: DeviceService,
    private snackBar: SnackBarService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public resendCode() {
    this.authyService.registerDevice({
      send_method: this.loginStateService.sendMethod
    }).subscribe({
      next: () => this.snackBar.openSnackBar('The code has been sent to your device.'),
      error: (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
      }
    );
  }

  public verifyCode() {
    if (!this.verifyCodeSubscription) {
      this.verifyCodeSubscription = this.authyService.verifyDevice({
        verification_code: this.validationCode
      }).subscribe({
          next: (response) => {
            this.createAuthorizationCookie(response.authorization);
            this.deviceService.userDeviceId = response.user_device_id;
            this.apiService.userAuthorization = response.authorization;
            this.apiService.setAuthorizationRole(response.authorization_role);
            if (response.authorization_role === 'ROLE_ACCEPT_TERMS') {
              this.router.navigate(['/notifications/accept-terms', {queryParamsHandling: 'preserve'}]);
            } else {
              if (this.redirectUrl) {
                this.dashboardRoutingService.redirectOnLogin(this.redirectUrl, this.isRedirectClassicUrl);
              } else {
                this.dashboardRoutingService.directToDashboard();
              }
            }
          },
          error: (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
        }
      );
      this.verifyCodeSubscription?.add(() => this.verifyCodeSubscription = null);
    }
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.redirectUrl = params.get('redirect') ? params.get('redirect') : null;
      this.isRedirectClassicUrl = params.get('isClassic') ? params.get('isClassic') === 'true' : false;
    });
  }

  ngAfterViewInit() {
    let phoneNumber = this.loginStateService.phoneNumber;
    this.snackBar.openSnackBar('A verification code was sent to: ' + phoneNumber);
  }

  private createAuthorizationCookie(authorization: string) {
    this.cookieService.put(
        this.apiService.authorizationHeaderName,
        authorization);
  }
}
