import { Component, OnInit, Input, Optional, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AccountBalanceComponent } from '../account-balance/account-balance.component';
import { AccountSwitcherNonRootComponent } from '../account-switcher-non-root/account-switcher-non-root.component';
import { AccountSwitcherRootComponent } from '../account-switcher-root/account-switcher-root.component';
import { SessionTimeoutService } from '../session-timeout-service/session-timeout.service';
import { DashboardPermissionsService, DashboardService, DashboardStateService } from '../../dashboard/dashboard.module';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import { HeaderService } from '../header-service/header.service';
import { HeaderStateService } from '../header-state-service/header.state.service';
import { RoutingService } from '../../core/services/routing.service';
import { NotificationUserListComponent } from '../notification-user-list/notification-user-list.component';
import { RegisterIconService } from '../../core/services/register-icon.service';
import { environment } from '../../../environments/environment';
import { NotificationApiService } from '../../core/services/api/notification-api.service';
import { ZendeskService } from '../../core/services/zendesk.service';
import { EntityGroupName, Notification } from '../../core/dtos/api';

@Component({
    selector: 'app-dashboard-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public headerTitle: string;

  constructor(
    public dashboardStateService: DashboardStateService,
    public headerStateService: HeaderStateService,
    private dashboardService: DashboardService,
    public dashboardApi: DashboardApiService,
    private dashboardRoutingService: RoutingService,
    private dialog: MatDialog,
    private headerService: HeaderService,
    private sessionTimeoutService: SessionTimeoutService,
    private dashboardPermissionsService: DashboardPermissionsService,
    private notificationApi: NotificationApiService,
    // RegisterIconService is injected, but not used, to register custom icons.
    private registerIconService: RegisterIconService,
    private zendeskService: ZendeskService,
    @Optional() private router: Router,
    @Optional() private activatedRoute: ActivatedRoute
  ) { }

  entityName: string;
  userIsAdmin: boolean;
  isClassic = environment.isClassic;
  dashboardUrl = environment.dashboardUrl;
  classicUrl = environment.classicUrl;
  location = window.location;
  environment = environment;

  classicRoute: string;
  classicRoute$Sub = this.dashboardRoutingService.classicRoute$.subscribe(x => this.classicRoute = x);

  entityGroupName: EntityGroupName;
  entityGroupName$ = this.dashboardPermissionsService.accountWithPermissions$.pipe(map(([x, ]) => x.entity_group_name));
  entityGroupName$Sub = this.entityGroupName$.subscribe(x => this.entityGroupName = x);

  permissions: {[key: string]: boolean};
  permissions$ = this.dashboardPermissionsService.accountWithPermissions$.pipe(map(([, y]) => this.dashboardPermissionsService.createPermissionLookUpArray(y)));
  permissions$Sub = this.permissions$.subscribe(x => this.permissions = x);

  userAnnouncementMessages: Notification[] = [];
  userAnnouncementMessages$Sub = this.notificationApi.userAnnouncementMessages$.subscribe(x => this.userAnnouncementMessages = x);

  setup$Sub = combineLatest([this.dashboardApi.currentAccount$, this.dashboardApi.currentUserInfo$.pipe(first())])
    .subscribe(([currentAccount, currentUser]) => {
      this.userIsAdmin = !!currentUser.user_permissions.find((userPermission) => userPermission.entity_group_name === 'root'
          && userPermission.entity_id === this.dashboardStateService.rootPermissions
          && userPermission.deleted_at === null);
      this.dashboardStateService.userIsAdmin = this.userIsAdmin;

      this.dashboardService.setupDashboardStateService(currentAccount, currentUser);
      this.sessionTimeoutService.startSessionTimeOut();
      this.entityName = currentAccount.entity_name === 'root' ? `${currentUser.first_name} ${currentUser.last_name}` : currentAccount.entity_name;

      if (this.userIsAdmin) {
        this.headerService.populateAccountSwitcherRootData();
      } else {
        this.headerService.populateAccountSwitcherNonRootAccounts();
      }
    },
    (errorResponse: HttpErrorResponse) => {
      if (errorResponse.status > 0) {
        this.dashboardRoutingService.directToRoute('Login');
      }
    });

  public ngOnInit() { }

  public ngOnDestroy() {
    this.classicRoute$Sub.unsubscribe();
    this.entityGroupName$Sub.unsubscribe();
    this.permissions$Sub.unsubscribe();
    this.userAnnouncementMessages$Sub.unsubscribe();
    this.setup$Sub.unsubscribe();
  }

  public openAccountBalance() {
    this.dialog.open(AccountBalanceComponent, {
      autoFocus: false,
      role: 'dialog',
      panelClass: ['account-balance-modal', 'dark'],
      position: {
        right: '12px',
        top: '56px'
      },
      width: '260px',
      height: '136px'
    });
  }

  public openUserNotifications() {
    this.dialog.open(NotificationUserListComponent, {
      role: 'dialog',
      panelClass: ['user-notification-modal', 'dark'],
      position: {
        right: '12px',
        top: '56px'
      }
    });
  }

  public openAccountSwitcher() {
    let dailogConf: MatDialogConfig = {
      role: 'dialog',
      panelClass: ['account-switcher-modal', 'dark'],
      position: {
        right: '80px',
        top: '56px'
      },
      width: '248px',
      autoFocus: false,
    };

    if (this.userIsAdmin) {
      this.dialog.open(AccountSwitcherRootComponent, dailogConf);
    } else {
      this.dialog.open(AccountSwitcherNonRootComponent, dailogConf);
    }
  }

  directToDashboard() {
    this.dashboardRoutingService.directToDashboard();
  }

  public openSupportTab() {
    this.dashboardRoutingService.routeToUrl('http://support.avantlink.com/hc/en-us', true);
  }

  public openZendeskHelp() {
    this.zendeskService.toggle();
  }
}
