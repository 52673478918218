import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

/** Make relative urls obsolute.  This can be removed when classic app goes away */
@Pipe({
    name: 'absUrl',
    standalone: false
})
export class AbsUrlPipe implements PipeTransform {
  transform(relativeUrl: string, ...args: any[]): string {
    return environment.dashboardUrl + relativeUrl;
  }
}
