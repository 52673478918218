import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { ConfirmationModalConfig } from './confirmation';

@Component({
    selector: 'app-confirmation-component',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    standalone: false
})

export class ConfirmationComponent {
  public title: string;
  public content: string;
  public positive_action_text: string;
  public negative_action_text: string;
  public checkbox_text: string | boolean;
  public positive_color: ThemePalette = 'primary';
  public negative_color: ThemePalette = 'primary';
  public checkbox_value: boolean;

  constructor(
    public dialog: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public config: ConfirmationModalConfig,
  ) {
    if (this.config) {
      this.title = this.config.title;
      this.content = this.config.content;
      this.positive_action_text = this.config.positive_action_text;
      this.negative_action_text = this.config.negative_action_text;
      this.checkbox_text = this.config.checkbox_text;
      this.negative_color = this.config.negative_color;
      this.positive_color = this.config.positive_color;
      this.checkbox_value = false;
    }
  }

  changeCheckbox = () => {
    this.checkbox_value = !this.checkbox_value;
  };
}
