import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isExtraWidthRequired',
    standalone: false
})
export class IsExtraWidthRequired implements PipeTransform {
  transform(posPartition: number, isLast: boolean, lastPartitionIndex: number, isHorizontalScrollBarVisible: boolean,
    isVerticalScrollBarVisible: boolean): boolean {
    return ((posPartition === lastPartitionIndex) && isLast && isHorizontalScrollBarVisible && isVerticalScrollBarVisible);
  }
}
