import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arePartitionOffsetsTheSame',
    standalone: false
})
export class ArePartitionOffsetsTheSame implements PipeTransform {
  transform(currentOffset: number, previousOffset: number, isFirstDisplayedPartition: boolean): boolean {
    // This is a special case where the first displayed header and the previous is the same, this header will still need to be displayed.
    if (isFirstDisplayedPartition && (currentOffset  === previousOffset)) {
      return false;
    } else {
      return currentOffset === previousOffset;
    }
  }
}
