import { Component, OnInit, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { NotificationApiService } from '../../core/services/api/notification-api.service';
import { notificationTypeTags } from '../../core/notification';
import { Notification } from '../../core/dtos/api';
@Component({
    selector: 'app-notification-user-list',
    templateUrl: './notification-user-list.component.html',
    styleUrls: ['./notification-user-list.component.scss'],
    standalone: false
})
export class NotificationUserListComponent implements OnInit, AfterViewInit {
  @ViewChildren('button') buttons: QueryList<ElementRef>;

  constructor(
    private notificationApi: NotificationApiService
  ) { }

  public userMessages: Notification[] = [];
  public loading = true;

  ngOnInit() {
    this.notificationApi.fetchUserMessages(notificationTypeTags.Announcement)
      .subscribe(incomingUserMessages => {
        this.loading = false;
        if (incomingUserMessages.length > 0) {
          this.userMessages = incomingUserMessages;
        }
      });
  }

  ngAfterViewInit() {
    this.buttons.changes
      .subscribe(buttonList => {
        if (buttonList.first) {
          buttonList.first.focus();
        }
      });
  }

  markAsRead(messageId, index) {
    this.notificationApi.updateMessageStatus(messageId, 'accepted').subscribe(() => {
      this.userMessages[index].status = 'accepted';
      this.notificationApi.refreshUserAnnouncementMessages$.next(undefined);
    });
  }

}
