import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../api/api.module';
import { AuthyApiService } from '../../api-services/authy-api/authy-api.module';
import { LoginStateService } from '../login-state-service/login-state.service';
import { DeviceService } from '../device-service/device.service';
import { GreetingService } from '../greeting/greeting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { LoginApiService } from '../../core/services/api/login-api.service';
import { RoutingService } from '../../core/services/routing.service';

@Component({
    // eslint-disable-next-line
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  public emailAddress: string;
  public password: string;
  public message: string;
  public isCapsLockOn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public disableLoginButton = false;
  private redirectUrl: string;
  private isRedirectClassicUrl: boolean;

  constructor(
    private apiService: ApiService,
    private loginApi: LoginApiService,
    private authyService: AuthyApiService,
    private deviceService: DeviceService,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardRoutingService: RoutingService,
    public loginStateService: LoginStateService,
    greetingService: GreetingService,
    private snackBar: SnackBarService,
  ) {
    this.message = greetingService.getGreeting();
  }

  public ngOnInit() {
    if (this.apiService.hasUserAuthorization && this.apiService.getAuthorizationRole() === 'ROLE_USER') {
      this.dashboardRoutingService.directToDashboard();
    }
    this.route.queryParamMap.subscribe(params => {
      this.redirectUrl = params.get('redirect') ? params.get('redirect') : null;
      this.isRedirectClassicUrl = params.get('isClassic') ? params.get('isClassic') === 'true' : false;
    });
  }

  public login() {
    this.disableLoginButton = true;
    this.loginApi.login({
      email_address: this.emailAddress,
      password: this.password,
      user_device_id: this.deviceService.userDeviceId
    }).subscribe({
      next: (loginResponse) => {
        this.dashboardRoutingService.homepage = loginResponse.classic_homepage;
        this.apiService.userAuthorization = loginResponse.authorization;
        this.apiService.setAuthorizationRole(loginResponse.authorization_role);
        if (loginResponse.authorization_role === 'ROLE_ACCEPT_TERMS') {
          this.router.navigate(['/notifications/accept-terms'], {queryParamsHandling: 'preserve'});
        } else {
          switch (loginResponse.mfa_current_step) {
            case 'setup': {
              this.router.navigate(['/login/setup-phone'], {queryParamsHandling: 'preserve'});
              break;
            }
            case 'invalid-device': {
              this.authyService.userInfo().subscribe({
                next: (response) => {
                  this.loginStateService.phoneNumber = response.phone_number;
                  this.router.navigate(['/login/register-device'], {queryParamsHandling: 'preserve'});
                },
                error: (errorResponse) => {
                  this.snackBar.openErrorSnackBar(errorResponse.error.error);
                }
              });
              break;
            }
            case 'completed': {
              if (this.redirectUrl) {
                this.dashboardRoutingService.redirectOnLogin(this.redirectUrl, this.isRedirectClassicUrl);
              } else {
                this.dashboardRoutingService.directToDashboard();
              }
              break;
            }
            default: {
              this.snackBar.openErrorSnackBar('An Unknown Error has occurred');
            }
          }
        }
      },
      error: (loginErrorResponse) => {
        this.disableLoginButton = false;
        if (loginErrorResponse.status === 403 &&
          loginErrorResponse.error.exception === 'InvalidArgumentException') {
          this.loginApi.sendResetCode({
            email_address: this.emailAddress
          }).subscribe({
              next: () => {
                this.loginStateService.emailAddress = this.emailAddress;
                this.loginStateService.initialPasswordSetup = true;
                this.router.navigate(['/login/reset-password']);
              },
              error: (sendResetCodeErrorResponse: HttpErrorResponse) => {
                this.snackBar.openErrorSnackBar(sendResetCodeErrorResponse.error.error);
              }
            });
        } else if (loginErrorResponse.status === 401 &&
          loginErrorResponse.error.exception === 'InvalidArgumentException' &&
          this.apiService.hasUserAuthorization) {
          this.apiService.userAuthorization = null;
          this.login();
        } else {
          this.snackBar.openErrorSnackBar(loginErrorResponse.error.error);
        }

      }
    });
  }

  public checkCapsLock(event: KeyboardEvent) {
    if (!!event.getModifierState) {
      this.isCapsLockOn.next(event.getModifierState('CapsLock'));
    }
  }
}
