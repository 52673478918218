import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCase',
    standalone: false
})
export class TitleCase implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    }
    return input.length > 0 ? input.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase())) : '';
  }
}
