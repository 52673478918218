import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, Observable } from 'rxjs';

import { AccountSwitcherService } from '../account-switcher-service/account-switcher.service';
import { DashboardStateService } from '../../dashboard/dashboard-state-service/dashboard-state.service';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import { HeaderStateService } from '../header-state-service/header.state.service';
import { JSONUtility } from '../../utilities/json-utility';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { UserSettingsApiService } from '../../core/services/api/user-settings-api.service';
import { RoutingService } from '../../core/services/routing.service';
import { SingleAccountTile } from '../header';
import { AuthService } from '../../core/services/auth.service';
import { environment } from '../../../environments/environment';
import { LoginApiService } from '../../core/services/api/login-api.service';
import {ReportFilter, SwitchAccountRequest} from '../../core/dtos/api';

@Component({
    selector: 'app-account-switcher-root',
    templateUrl: './account-switcher-root.component.html',
    styleUrls: ['./account-switcher-root.component.scss'],
    standalone: false
})
export class AccountSwitcherRootComponent implements OnInit, AfterViewInit {
  public filteredAccountResults: SingleAccountTile[];
  public searchTermStream = new Subject<string>();
  public filterRequestArray: ReportFilter[];
  public filterRequest: ReportFilter;
  public favorites: SingleAccountTile[];
  public showFavorites: boolean;

  private sendSwitchAccountSubscription: Subscription;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    private accountSwitcherService: AccountSwitcherService,
    private dashboardStateService: DashboardStateService,
    private dashboardApi: DashboardApiService,
    private dashboardRoutingService: RoutingService,
    private headerStateService: HeaderStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: SnackBarService,
    private userSettingsApi: UserSettingsApiService,
    private loginApiService: LoginApiService,
    public modal: MatDialog,
    private authService: AuthService
  ) {
  }

  public ngOnInit() {
    this.filterRequest = { field: '', op: 'eq', values: [] };
    this.filterRequestArray = [];
    this.searchTermStream.pipe(
      debounceTime(300),
      distinctUntilChanged(), )
      .subscribe(response => {
        this.filterAccounts(response);
      });
    this.getInitialFavorites().subscribe((favorites) => {
      this.favorites = favorites;
      this.showFavorites = true;

      if (this.dashboardStateService.entityId !== this.dashboardStateService.rootPermissions) {
        this.headerStateService.rootActiveAccount.toggleSwitch = {
          state: this.isFavorite(this.headerStateService.rootActiveAccount.entityId)
        };
      }
    });
  }

  public ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
    // this prevents the expressionChangedAfterChecked error
    this.changeDetectorRef.detectChanges();
  }

  public get networkAccounts() {
    return this.headerStateService.rootNetworkAccounts;
  }

  public get activeAccount() {
    return this.headerStateService.rootActiveAccount;
  }

  public changeNetwork(networkAccount: SingleAccountTile) {
    let switchRequest: SwitchAccountRequest = {
      entity_id: networkAccount.entityId,
      entity_group_name: networkAccount.entityGroupName,
      entity_network: networkAccount.entityNetwork
    };

    if (!this.sendSwitchAccountSubscription) {
      this.sendSwitchAccountSubscription = this.accountSwitcherService.switchCurrentAccount(switchRequest, window.location.pathname)
        .subscribe(switchAccountResponse => {
          if (environment.isClassic) {
            this.dashboardRoutingService.directToDashboard();
          }
          this.dashboardStateService.entityId = switchAccountResponse.entity_id;
          this.dashboardStateService.entityName = networkAccount.entityName;
          this.dashboardStateService.entityNetwork = switchAccountResponse.entity_network;
          this.dashboardStateService.entityGroupName = switchAccountResponse.entity_group_name;
          if (!this.dashboardStateService.userDefaultCurrency) {
            this.dashboardStateService.userDefaultCurrency = switchAccountResponse.currency.currency_name;
          }

          this.headerStateService.rootNetworkAccounts = this.headerStateService.rootNetworkAccounts.map(account => {
            if (account.entityNetwork !== networkAccount.entityNetwork) {
              account.isTileSelectable = true;
              account.isTileSelected = false;
            } else {
              account.isTileSelectable = false;
              account.isTileSelected = true;
            }

            return account;
          });

          this.headerStateService.rootActiveAccount = null;
          this.modal.closeAll();

        }, (errorResponse: HttpErrorResponse) => {
          this.snackBar.openErrorSnackBar(errorResponse.error.error);
        });
      this.sendSwitchAccountSubscription?.add(() => this.sendSwitchAccountSubscription = null);
    }
  }

  public logout() {
    this.modal.closeAll();
    this.authService.logout();
  }

  public switchAccount(selectedAccount: SingleAccountTile) {
    let switchRequest: SwitchAccountRequest = {
      entity_id: selectedAccount.entityId,
      entity_group_name: selectedAccount.entityGroupName,
      entity_network: selectedAccount.entityNetwork
    };
    if (!this.sendSwitchAccountSubscription) {
      this.sendSwitchAccountSubscription = this.accountSwitcherService.switchCurrentAccount(switchRequest, window.location.pathname)
        .subscribe(switchAccountResponse => {
          if (environment.isClassic) {
            this.dashboardRoutingService.directToDashboard();
          }
          this.dashboardStateService.entityId = switchAccountResponse.entity_id;
          this.dashboardStateService.entityName = switchAccountResponse.entity_name;
          this.dashboardStateService.classicId = switchAccountResponse.classic_entity_id;
          this.dashboardStateService.entityNetwork = switchAccountResponse.entity_network;
          this.dashboardStateService.entityGroupName = switchAccountResponse.entity_group_name;
          if (!this.dashboardStateService.userDefaultCurrency) {
            this.dashboardStateService.userDefaultCurrency = switchAccountResponse.currency.currency_name;
          }
          this.headerStateService.rootActiveAccount = selectedAccount;
          this.headerStateService.rootNetworkAccounts.forEach(network => {
            network.isTileSelectable = true;
            network.isTileSelected = false;
          });
          this.modal.closeAll();

        }, (errorResponse: HttpErrorResponse) => {
          this.snackBar.openErrorSnackBar(errorResponse.error.error);
        });
      this.sendSwitchAccountSubscription?.add(() => this.sendSwitchAccountSubscription = null);
    }
  }

  public getInput(inputValue: string) {
    this.searchTermStream.next(inputValue);
  }

  public filterAccounts(input: string) {
    if (input.length > 2) {
      this.loginApiService.searchAccounts(input, 'any')
        .subscribe(searchAccountResponse => {
          this.filteredAccountResults = searchAccountResponse.map(account => ({
              classicId: account.classic_entity_id,
              entityName: account.entity_name,
              entityNetwork: account.entity_network,
              entityGroupName: account.entity_group_name,
              entityId: account.entity_id,
              isTileSelected: false,
              isTileSelectable: true,
              toggleSwitch: {
                state: this.isFavorite(account.entity_id)
              }
            }));
          this.showFavorites = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.snackBar.openErrorSnackBar(errorResponse.error.error);
        });
    } else {
      this.showFavorites = true;
      this.filteredAccountResults = [];
    }
  }

  public handleAccountFavoritesToggle(accountTileData: SingleAccountTile): void {
    if (accountTileData.toggleSwitch.state) {
      accountTileData.toggleSwitch.state = false;
      this.favorites = this.favorites.filter((accountFavorite) => accountFavorite.entityId !== accountTileData.entityId);
    } else if (!this.isFavorite(accountTileData.entityId)) {
      accountTileData.toggleSwitch.state = true;
      this.favorites.push(accountTileData);
    }

    accountTileData.isTileSelected = false;
    if (this.dashboardStateService.entityId !== this.dashboardStateService.rootPermissions) {
      if (accountTileData.entityId === this.activeAccount.entityId) {
        this.activeAccount.toggleSwitch.state = accountTileData.toggleSwitch.state;
      }
    }

    let requestFavorites = [null];
    if (this.favorites.length) {
      requestFavorites = JSONUtility.encodeJSONArray<SingleAccountTile>(this.favorites);
    }
    this.userSettingsApi.putAccountFavoritesSetting(this.dashboardStateService.userId, requestFavorites).subscribe((favorites) => {
      this.favorites = (favorites && favorites.key_value[0]) ? JSONUtility.decodeJSONArray<SingleAccountTile>(favorites.key_value) : [];
    }, (err) => {
      this.snackBar.openErrorSnackBar(`We were unable to save your changes. Please try again.`);
    });
  }

  private getInitialFavorites(): Observable<SingleAccountTile[]> {
    return this.userSettingsApi.getAccountFavoritesSetting$.pipe(map((favorites) => {
      this.favorites = (favorites && favorites[0].key_value[0]) ? JSONUtility.decodeJSONArray<SingleAccountTile>(favorites[0].key_value) : [];
      return this.favorites.map((fav) => {
        fav.toggleSwitch.state = true;
        fav.isTileSelected = false;
        return fav;
      });
    }));
  }

  private isFavorite(entityId: string): boolean {
    return !!this.favorites.find((favorite) => favorite.entityId === entityId);
  }
}

