import { Component } from '@angular/core';

@Component({
    selector: 'app-login-container',
    templateUrl: './login-container.component.html',
    styleUrls: ['./login-container.component.scss'],
    standalone: false
})
export class LoginContainerComponent {
  constructor() { }
}
