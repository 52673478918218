import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

/** Determines whether an array or string includes a certain value among its entries. */
@Pipe({
    name: 'includes',
    standalone: false
})
export class IncludesPipe implements PipeTransform {
  transform<T>(value: ReadonlyArray<T>, valueToFind: T, fromIndex?: number): boolean;
  transform(value: string, valueToFind: string, fromIndex?: number): boolean;
  transform(value: any, valueToFind: any, fromIndex = 0): boolean {
    if (value == null) {
      return false;
    }

    return value.includes(valueToFind, fromIndex);
  }
}
