import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DashboardApiService } from './core/services/api/dashboard-api.service';
import { RegisterIconService } from './core/services/register-icon.service';

import { ApiService } from './api/api.module';
import { environment } from '../environments/environment';
import { of } from 'rxjs';
import { CurrentUserAccount } from './core/dtos/api';
import { catchError, filter, switchMap, map, first, shareReplay, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { DashboardHttpService } from './core-services/dashboard-http-service/dashboard-http.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private apiService: ApiService,
    private dashboardApiService: DashboardApiService,
    private dashboardHttpService: DashboardHttpService,
    private registerIconService: RegisterIconService, // RegisterIconService is injected, but not used, to register custom icons.
  ) {
    if (environment.enableAnalytics) {
      window['gtag']('config', 'G-3HEL8X0RTP');

      let currentAccountCaught = this.dashboardApiService.currentAccount$.pipe(
        catchError(() => of<CurrentUserAccount>(null)));

      this.router.events.pipe(
        filter<NavigationEnd>(event => event instanceof NavigationEnd),
        switchMap(event => {
          if (this.isLogin) {
            return of(<const> [event, <CurrentUserAccount> null]);
          }
          return currentAccountCaught.pipe(first(), map(currentAccount => <const> [event, currentAccount]));
        }))
        .subscribe(([event, currentAccount]) => {
          window['gtag']('custom_map', 'page', event.urlAfterRedirects);
          window['gtag']('custom_map', 'dimension2', currentAccount?.entity_group_name ?? '');
        });

      /* eslint-disable */
      // reason: snippit provided by Google Tag Manager
      (function(w: any,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';(<any> j).async=true;(<any> j).src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MRX6C5F');
      /* eslint-enable */

      /* eslint-disable */
      // reason: snippit provided by HotJar
      (function(h: any,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2381846,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      /* eslint-enable */
    }
  }

  globalLoadingVisibility$ = this.dashboardHttpService.showGlobalLoading$.pipe(
    debounceTime(5),
    distinctUntilChanged(),
    map(x => x ? null : 'hidden'));

  isShopifyPage$ = this.router.events.pipe(
    filter((event => event instanceof NavigationEnd)),
    map(event => (<NavigationEnd> event).url.startsWith(this.dashboardHttpService.shopifyStartPath)),
    shareReplay(1)
  );

  @HostBinding('class.login')
  public get isLogin() {
    return this.dashboardHttpService.loginStartPaths.some(x => this.router.url.startsWith(x));
  }

  public ngOnInit() {
    this.apiService.refreshHeaders();
  }
}
