import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getMinimumNumber',
    standalone: false
})
export class GetMinimumNumber implements PipeTransform {
  transform(firstNumber: number, secondNumber: number): number {
    return Math.min(firstNumber, secondNumber);
  }
}
