import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { ReportApiService } from '../../api-services/report-api/report-api.service';

@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe-report.component.html',
    styleUrls: ['./unsubscribe-report.component.scss'],
    standalone: false
})
export class UnsubscribeReportComponent implements OnInit {
  public unsubscribeSuccessful: boolean;
  constructor(
    private reportApi: ReportApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: SnackBarService,
  ) { }

  public ngOnInit() {
    let reportId = this.route.snapshot.paramMap.get('reportId');
    let userId = this.route.snapshot.paramMap.get('userId');
    let reportSubscriptionId = this.route.snapshot.paramMap.get('reportSubscriptionId');
    let authToken = this.route.snapshot.paramMap.get('authorizationToken');

    this.reportApi.unsubscribeReport(userId, reportId, reportSubscriptionId, authToken)
      .subscribe((response) => {
        this.unsubscribeSuccessful = true;
      }, (errorResponse) => {
        this.unsubscribeSuccessful = false;
        this.snackBar.openErrorSnackBar(errorResponse.error.error);
      });
  }

  public routeToLogin() {
    this.router.navigate(['/login']);
  }
}
