import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginStateService } from '../login-state-service/login-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { LoginApiService } from '../../core/services/api/login-api.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: false
})
export class ForgotPasswordComponent implements OnInit {
  private sendResetCodeSubscription: Subscription;

  constructor(
    private loginApi: LoginApiService,
    private router: Router,
    public loginStateService: LoginStateService,
    private snackBar: SnackBarService,
    ) { }

  public ngOnInit() { }

  public sendResetCode() {
    if (!this.sendResetCodeSubscription) {
      this.sendResetCodeSubscription = this.loginApi.sendResetCode({
        email_address: this.loginStateService.emailAddress
      }).subscribe(
        () => this.router.navigate(['/login/reset-password']),
        (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
        );
      this.sendResetCodeSubscription?.add(() => this.sendResetCodeSubscription = null);
    }
  }
}
